









































import IconMixin from "@/components/place/filter/icon-mixin.vue";

export default IconMixin.extend({
  name: "filter-icon-qticket",
});
